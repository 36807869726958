<template>
  <div>
    <main class="page__container">
      <div class="container">
        <el-row justify="space-between" :gutter="20">
          <el-col :span="24">
            <h2 class="page__title">
              Добавить серийный номер
            </h2>
          </el-col>
        </el-row>
        <el-form ref="form" :rules="rules" :model="form">
          <el-row :gutter="20">
              <el-col :span="8">
                <el-form-item label="Модель" prop="id_model"><br>
                    <c-el-select-model size="small" @select-item="val => form.id_model = val.id_model"></c-el-select-model>
                </el-form-item>
              </el-col>
            <el-col :span="16 ">
              <el-form-item label="Серийный номер" prop="serial_number">
                <el-input size="small" v-model="form.serial_number"  placeholder="Серийный номер" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </main>
    <el-footer class="page__active_bar">
      <el-button :loading="loading" class="page__active_button" type="primary" icon="el-icon-check" @click="onSave">
        Сохранить
      </el-button>
    </el-footer>
  </div>
</template>
<script>
import { required } from "@/core/helpers/validators";
import { ApiService } from "@/api";
import SelectModel from "./SelectModel";

export default {
  name: "SerialNumberCreate",
  components: {
    "c-el-select-model": SelectModel
  },
  data() {
    return {
      loading: false,
      form: {
        id_model: null,
        serial_number: "",
      },
      rules: {
        id_model: [required('number')],
        serial_number: [required()]
      },
    };
  },
  methods: {
    onSave() {
      let formIsValid;
      this.$refs["form"].validate((status) => {
        formIsValid = status;
      });

      if (!formIsValid) return false;

      this.loading = true;

      const newSerialNumber = Object.assign({}, this.form);
      ApiService.serialnumbers
        .create(newSerialNumber)
        .then(() => {
          this.$message({
            type: "success",
            message: "Cерийный номер добавлен",
          })
          this.$refs["form"].resetFields();
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: e.response.data.error || "Неизвестная ошибка",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.page_container {
  padding: 20px;
}
h2 {
  color: #606266
}
</style>
