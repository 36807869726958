<template>
  <el-select
    :value="query"
    filterable
    remote
    :size="size"
    placeholder="Поиск по моделям"
    style="width: 100%"
    :loading="loading && !models.length"
    :remote-method="remoteMethod"
    @change="change"
  >
    <el-option
      v-for="model in models"
      :key="model.id_model"
      :value="model.id_model"
      :label="model.name"
      @click.native="selectItem(model)"
    >
      <span style="float: left">{{ model.name }}</span>
      <!-- <span style="float: right; color: #8492a6; font-size: 13px; margin-left: 10px">{{ model.id_model }}</span> -->
    </el-option>
    <el-option
      v-if="total && total - models.length"
      :value="0"
      disabled
      style="align-items: center; text-align: center"
    >
      <el-button :loading="loading && !queryLoading" type="text" style="width: 100%" size="mini" @click="nextPage">
        {{ loading && !queryLoading ? "Загрузка" : "Показать ещe" }}
      </el-button>
    </el-option>
  </el-select>
</template>

<script>
import { ApiService } from "@/api";

export default {
  name: "SelectModel",
  props: {
    modelId: {
      type: Number,
      default: null,
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      page: 1,
      query: "",
      total: 0,
      queryLoading: false,
      loading: false,
      models: [],
    };
  },
  mounted() {
    if (this.modelId) {
      this.loading = true;
      ApiService.models.get(this.modelId).then((model) => {
        this.models = [model];
      });
    }
    this.pullModels(true);
  },
  methods: {
    clearData() {
      this.page = 1;
      this.total = 0;
      this.models = [];
    },
    remoteMethod(query) {
      this.queryLoading = true;
      this.query = encodeURI(query);
      this.clearData();
      this.pullModels().finally(() => {
        this.queryLoading = false;
      });
    },
    nextPage() {
      this.page = this.page + 1;
      this.pullModels(true);
    },
    pullModels(update = false) {
      const { page, query: search } = this;

      this.loading = true;
      return ApiService.models
        .getList({ search, page, count: 10 })
        .then(({ data: models, total }) => {
          this.total = total;
          this.models = (update ? [...this.models, ...models] : models).filter((model, index, array) => {
            return !array.slice(index + 1).find((c) => c.id_model === model.id_model);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change(id) {
      const selectedmodels = this.models.find((c) => c.id_model === id);
      this.$emit("change", selectedmodels);
    },
    selectItem (item) {
      this.query = item.name;
      this.$emit('select-item', item)
    }
  },
};
</script>
